/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser';

function SEO({ domen, lang, meta, title, locationOrigin, contentBody,overviewDesc, image}) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
					}
				}
			}
		`
	)

	var entityMap = {
				'&': '&amp;',
				'<': '&lt;',
				'>': '&gt;',
				'"': '&quot;',
				"'": '&#39;',
				'/': '&#x2F;',
				'`': '&#x60;',
				'=': '&#x3D;'
			};

	var body = null
	var overview = null
	var bodyPlain = null
	var overviewPlain = null
	var metaData = null


	if(contentBody) {
		body = String(contentBody.processed).replace(/[&<>"'`=\/]/g, function (s) {
					return entityMap[s];
		});
		bodyPlain = String(contentBody.processed).replace(/(<([^>]+)>)/ig, '');
		bodyPlain = String(bodyPlain).replace(/[&<>"'`=\/]/g, function (s) {
					return entityMap[s];
		});
	}


	var metaDescription, keywords
	if(metaData !== null) {
		metaDescription = metaData.field_description || site.siteMetadata.description
		keywords = metaData.field_title || title
	} else {
		metaDescription = site.siteMetadata.description
		keywords = title
	}

	var creator = "@apco"
	var cardType = "summary"
	var locationOr = ""
	if(locationOrigin) {
		locationOr = locationOrigin
	}

	var metaImage
	var tImage,height,width,tDescription
	if(image !== null && image !== undefined) {
		 metaImage = domen+image
		 tImage = domen+image
		height = '480'
		width = '480'
	} else {
		 metaImage = null
		 height = null
		 width = null
		 tImage = null
	}


	tDescription = metaDescription


	var metaArr = [
				{
					name: `description`,
					content: metaDescription,
				},
				{
					name: `keywords`,
					content: keywords
				},
				{
					property: `og:title`,
					content: title,
				},

				{
					property: `og:url`,
					content: locationOr,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: cardType,
				},
				{
					name: `twitter:creator`,
					content: creator,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: tDescription,
				},
			]
			if(metaImage) {
					metaArr.push(
						{
							property: `og:image`,
							content: metaImage,
						},
						{
							property: `og:image:secure_url`,
							content: metaImage,
						},
						{
							property: `og:image:width`,
							content: width,
						},
						{
							property: `og:image:height`,
							content: height,
						},
					)
			}
			if(tImage) {
				metaArr.push(
					{
						name: `twitter:image`,
						content: tImage,
					}
				)
			}

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={parse(title+" - " + site.siteMetadata.title)}
			titleTemplate={`%s`}
			meta={metaArr.concat(meta)}
		/>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
}

export default SEO
