import React from "react";
import Header from "./header.js";
import Footer from "./footer.js";

// UIkit
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/css/uikit-core.min.css";
import "uikit/dist/js/uikit.min.js";
// Custom style
import "../styles/scss/main.scss";

function Layout({ children, pagePath }) {

	const [mainClass, setMainClass] = React.useState('');

	React.useEffect(() => {

		if(pagePath === '/'){
			setMainClass('main-homepage');
		}

	},[pagePath]);


	return (
		<div className="layout">
			<Header />
			<main className={mainClass}>{children}</main>
			<Footer />
		</div>
	);
}

export default Layout;
