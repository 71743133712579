import React, {useState} from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const getData = graphql`
	query Navbar {
		wp {
			themeGeneralSettings {
				singlePagesRelations {
					tablePressCustomCss
				}
				header {
					header {
						logoUrl
						logoImage {
							sourceUrl
						}
						socialLinks {
							socialLink
							socialIcon {
								sourceUrl
							}
						}
						logoImageMobile {
							sourceUrl
						}
					}
				}
			}
		}
		wpMenu(name: {eq: "Header menu"}) {
			menuItems {
				nodes {
					url
					label
					target
				}
			}
		}
	}
`;

function Header() {
	const data = useStaticQuery(getData);
	const { logoUrl, logoImage, socialLinks, logoImageMobile } =
		data.wp.themeGeneralSettings.header.header;
	const navMenuItems = data.wpMenu.menuItems.nodes

	const [isNavbarOpen, setIsNavbarOpen] = useState(false);

	const logoImg = logoImage.sourceUrl.replace(process.env.WP_SITE_URL,"/");

	const logoImgMob = logoImageMobile.sourceUrl.replace(process.env.WP_SITE_URL,"/");
	const customCSS = data.wp.themeGeneralSettings.singlePagesRelations.tablePressCustomCss;

	return (
		<div className="header">
			<div className="custom-style" dangerouslySetInnerHTML={{__html: '<style>'+customCSS+'</style>'}}  />
			<div className="container">
				<div className="header-wrapper">
					<Link to={logoUrl} className="logo-wrapper">
						<img
							className="header-logo"
							src={logoImg}
							alt=""
						/>
						<img
							className="header-mobile-logo"
							src={logoImgMob}
							alt=""
						/>
					</Link>
					<ul className={`header-links ${isNavbarOpen ? "active" : ""}`}>
						{navMenuItems
							? navMenuItems.map((item, index) => (
									<li key={index}>
										{item.target && item.target === "_blank" ? (
											<a className="header-link" href={item.url || "/"} target="_blank" rel="noreferrer">{item.label || ""}</a>
										) : (
											<Link className="header-link" to={item.url || "/"}>{item.label || ""}</Link>
										)}
									</li>
							  ))
							: null}
					</ul>
					<ul className="header-social-links">
						{socialLinks
							? socialLinks.map((item, index) => (
									<li key={index}>
										<a href={item.socialLink || "/"} rel="noreferrer" target="_blank">
											{item.socialIcon ? (
												<img
													className="header-social-link"
													src={
														`${item.socialIcon
															.sourceUrl.replace(process.env.WP_SITE_URL,"/")}`
													}
													alt="social"
												/>
											) : (
												""
											)}
										</a>
									</li>
							  ))
							: null}
					</ul>
					<button type="button" className="header-toggle" onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
						<span className="header-icon-bar"></span>
						<span className="header-icon-bar"></span>
						<span className="header-icon-bar"></span>
					</button>
				</div>
			</div>
		</div>
	);
}

export default Header;
