import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const getData = graphql`
	query Footer {
		wpMenu(name: {eq: "Footer Menu"}) {
			menuItems {
				nodes {
					url
					label
					target
				}
			}
		}
	}
`;

function Footer() {

	const data = useStaticQuery(getData);
	const footerMenu = data.wpMenu && data.wpMenu.menuItems.nodes;
	var currentYear = new Date().getFullYear();
	return (
		<div className="footer">
			<div className="footer-wraper container">
				<p className="footer-copyright" dangerouslySetInnerHTML={{__html: '&copy; ' + currentYear + ' Alpha Metallurgical Resources'}} />
				<ul className="footer-links">
					{footerMenu ? (
						footerMenu.map((item, index) => (
							<li key={index}>
								{item.target && item.target === '_blank' ? (
									<a className="footer-link" href={item.url} target="_blank" rel="noreferrer">{item.label}</a>
								) : (
									<Link className="footer-link" to={item.url}>{item.label}</Link>
								)}
							</li>
						))
					) : (
						null
					)}
				</ul>
			</div>
		</div>
	);
}

export default Footer;
